<template>
	<div class="page barnd">
		<img :src="info.large" id="summarized" />
		<el-breadcrumb separator=">>">
			<el-breadcrumb-item :to="{ path: '/' }" replace>
				{{ lang == 6 ? '首页' : 'Front Page ' }}
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				<span style="color: #97233F;">{{ info.title }}</span>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="textBox" style="margin-bottom: 0;">
			<p v-html="info.describe" class="text"></p>
		</div>
		<div class="textBox" id="Gallery">
			<p class="title" style="margin-top: 20px;">{{ lang == 6 ? id==39? '展示':'车型展示' : 'Display' }}</p>
		</div>
		<div class="mode2">
			<img :src="banner[bannerIndex] ? banner[bannerIndex].image : ''" class="bg">
			<img src="../assets/arrows.png" class="arrows" @click="$refs.mySwiper1.swiper.slideNext()" />
			<img src="../assets/arrows.png" class="arrows" @click="$refs.mySwiper1.swiper.slidePrev()" />
			<swiper :options="swiperOption" ref="mySwiper1" @slideChange="slideChange" v-if="banner.length > 0">
				<swiper-slide class="swiper-slide" v-for="(item, index) in banner" :key="index">
					<img :src="item.image" mode="widthFit" class="banner" @click="showImgViewer(index)" />
					<p>{{ item.title }}</p>
				</swiper-slide>
			</swiper>
		</div>
		<div class="mode4" v-if="id == 5 || id == 20">
			<div class="mode4-text" :class="lang == 6 ? 'mode4-textCn' : ''">
				<div class="titTop">{{ lang == 6 ? '保时捷控股车队' : 'TEAM Porsche HOLDing' }} </div>
				<p class="titText" v-if="lang == 6">
					为中国及亚洲的赛车运动发展持续贡献心力！
				</p>
				<p class="titText" v-else>
					Contributing to the development of motorsports in China and Asia！
				</p>
				<div class="titbtn" @click="$router.push('/porsche')">{{ lang == 6 ? '了解更多' : 'More Details' }}</div>
			</div>
		</div>
		<div class="mode3" id="activity">
			<div class="title-flexBox">
				<div class="title">
					{{ info.title }}{{ lang == 6 ? '活动' : ' activities' }}
				</div>
				<selectBox search_type="activity" :brandId="id" @change="page = 1; changeSelectBox($event)"></selectBox>
			</div>
			<div class="mode3-list" v-if="activityList.length > 0">
				<div class="mode3-list-item" v-for="(item, index) in activityList" :key="index">
					<div class="mode3-list-item-text">
						<div>
							<p class="title">{{ item.title }}</p>
							<div class="mode3-list-item-text-describe" v-html="item.describe"></div>
						</div>
						<div>
							<div>
								<i class="el-icon-time"></i>
								<span>{{ item.business_time }}</span>
							</div>
						</div>
					</div>
					<div class="mode3-right">
						<img src="../assets/arrows1.png" :style="item.swiperCur == 0 ? 'opacity: .3' : ''"
							class="arrows1" @click="swiperPrev(index)" />
						<img :style="item.swiperCur == item.atlas.length - 1 ? 'opacity: .3' : ''"
							src="../assets/arrows1.png" class="arrows1" @click="swiperNext(index)" />
						<swiper @slide-change-transition-end="handleClickSlide(index)" :options="swiperOption1"
							:ref="'mode3Swper' + index">
							<swiper-slide v-for="(image, j) in item.atlas" :key="j">
								<el-image :src="image" mode="widthFit" :preview-src-list="item.atlas"
									class="previewImage"></el-image>
							</swiper-slide>
						</swiper>
					</div>

				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total" :current-page="page"
				@current-change="changePage">
			</el-pagination>
		</div>
		<div class="textBox" id="dealer">
			<p class="title findUs">{{ lang == 6 ? '找到我们' : 'Contact Us' }}</p>
		</div>
		<div class="bottom" v-loading="mapLoading">
			<div class="map" id="map"></div>
			<div class="right">
				<div class="inputBox">
					<div>
						<el-select v-model="province" :popper-append-to-body="false"
							:placeholder="lang == 6 ? '请选择省份' : 'Please select a province'"
							@change="dealer = after = city = agency = ''; cityList = []; afterList = []; Bomlist = []; getCityList();">
							<el-option v-for="(item, index) in provinceList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="city" :popper-append-to-body="false"
							:placeholder="lang == 6 ? '请选择城市' : 'Please select a city'"
							@change="dealer = after = agency = ''; afterList = []; Bomlist = []; getDealer()">
							<el-option v-for="(item, index) in cityList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<el-select v-model="dealer" :popper-append-to-body="false"
						:placeholder="lang == 6 ? '请选择经销商' : 'Please select distributor'"
						@change="changeDealer($event, '')">
						<el-option v-for="(item, index) in dealerList" :key="index" :label="item.title" :value="index">
						</el-option>
					</el-select>
					<el-select v-if="afterList.length > 0" v-model="after" :popper-append-to-body="false"
						:placeholder="lang == 6 ? '请选择售后' : 'Please select after sale'"
						@change="changeDealer($event, 'after')">
						<el-option v-for="(item, index) in afterList" :key="index" :label="item.title" :value="index">
						</el-option>
					</el-select>
				</div>
				<div class="rightList">
					<div v-if="!agency.subtitle">
						<div class="rightList-item" v-for="(item, index) in Bomlist" :key="index"
							@click="getSiteDetail(index)">
							<div class="rightListTit"><span
									class="num">{{ index + 1 < 10 ? ('0' + (index + 1)) : index + 1 }}</span>{{ item.subtitle }}
							</div>
							<div v-html="item.describe" class="describe"></div>
						</div>
					</div>
					<div v-else>
						<div class="rightListTit">{{ agency.subtitle }}</div>
						<div v-html="agency.describe" class="describe"></div>
					</div>
				</div>
			</div>
		</div>
		<el-image-viewer v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgList" />

	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import selectBox from '../components/selectBox.vue'
	var mapIcon =
		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAASdAAAEnQB3mYfeAAAABVJREFUCB0BCgD1/wAAAAAAAAAAAAAACgABHBrFXwAAAABJRU5ErkJggg=='

	export default {
		name: 'HelloWorld',
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide,
			selectBox,
			'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
		},
		data() {
			return {
				imgViewerVisible: false,
				mapLoading: false,
				id: '',
				info: {
					large: ''
				},
				page: 1,
				limit: 3,
				total: 0,
				swiper: true,
				swiperOption: {
					slidesPerView: 3,
					spaceBetween: 30,
					centeredSlides: true,
					loop: true,
					loopFillGroupWithBlank: true,
					observer: true,
					observeParents: true,
					slideToClickedSlide: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					}
				},
				banner: [],
				bannerFilterList: [],
				imgList: [],
				bannerIndex: 0,
				activityList: [],
				dealer: '',
				city: '',
				province: '',
				provinceList: [],
				provinceObj: {},
				cityList: [],
				dealerList: [],
				Bomlist: [],
				afterList: [],
				after: '',
				curLocation: '',
				curCity: '',
				url: '',
				map: '',
				markerList: [],
				agency: {},
				swiperOption1: {
					slidesPerView: '1',
					loop: false,
				}
			}
		},
		watch: {
			// 监听路由是否变化
			'$route'(to, from) {
				Object.assign(this.$data, this.$options.data())
				if (to.params.id != from.params.id) {
					this.id = to.params.id; // 把最新id赋值给定义在data中的id
					this.init()
					this.getDealer()
					this.getInfo()
				}
			},
			lang: function(a, b) {
				Object.assign(this.$data, this.$options.data())
				this.getInfo()
				this.init()
			}
		},
		deactivated() {
			Object.assign(this.$data, this.$options.data())
		},
		mounted() {
			this.init()
		},
		methods: {
			showImgViewer(index) {
				this.imgList = this.bannerFilterList.slice(index, this.bannerFilterList.length).concat(this
					.bannerFilterList.slice(0, index))
				this.imgViewerVisible = true;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'hidden';
				document.addEventListener("touchmove", m, false); // 禁止页面滑动

			},
			closeImgViewer() {
				this.imgViewerVisible = false;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'auto';
				document.removeEventListener("touchmove", m, true);
			},
			init() {
				var that = this
				that.id = that.$route.params.id
				if (this.$route.query.id) {
					document.querySelector('#' + this.$route.query.id).scrollIntoView(true)
				}
				var geolocation = new BMapGL.Geolocation();
				this.map = new BMapGL.Map('map');
				this.map.centerAndZoom(new BMapGL.Point(116.514, 39.915), 10);
				this.map.enableScrollWheelZoom(true);

				// 获取位置
				geolocation.getCurrentPosition(function(r) {
					if (this.getStatus() == BMAP_STATUS_SUCCESS) {
						that.curLocation = r.latitude + ',' + r.longitude
						that.latitude = r.latitude
						that.longitude = r.longitude
						that.curCity = r.address.city
						that.getDealer()
					} else {
						that.getDealer()
					}
				})
			},
			handleClickSlide(index) {
				this.activityList[index].swiperCur = this.$refs['mode3Swper' + index][0].swiper.realIndex
			},
			async getDealer() {
				console.log('=======================aaaaa')
				var that = this
				var res = await that.$api.getDealerList({
					// cate_no: '3rv54b65n',
					brand_id: that.id,
					latitude: that.latitude,
					longitude: that.longitude,
					city_id: that.city,
					province_id: that.city ? '' : that.province,
				})
				that.dealerList = res.list
				that.Bomlist = res.list
				that.createPoint()
			},
			createPoint() {
				console.log('创建点')
				if (!this.map) return
				var that = this
				that.markerList = []
				let bPoints = []
				that.map.clearOverlays();
				var myIcon = new BMapGL.Icon(mapIcon, new BMapGL.Size(40, 64));
				that.Bomlist.forEach((item, index) => {
					// 创建点标记
					let point = new BMapGL.Point(item.longitude, item.latitude)
					bPoints.push(point)
					let marker = new BMapGL.Marker(point, {
						icon: myIcon
					});
					marker.setLabel(new BMapGL.Label(index + 1, {
						offset: new BMapGL.Size(-5, -15)
					}));
					that.markerList.push(marker)
					that.map.addOverlay(marker);
					marker.addEventListener("click", () => {
						that.getSiteDetail(index)
					})
				})
				this.setZoom(bPoints)
				// this.map.centerAndZoom(new BMapGL.Point(that.dealerList[0].longitude, that.dealerList[0].latitude), 10);
			},
			getSiteDetail(i) {
				var that = this
				var info = that.Bomlist[i]
				this.map.centerAndZoom(that.markerList[i].point, 15)
				let content = `<div id="infoWindow">
					<div class="infoWindowText">${info.address}</div>
					<div class="btn" id="infoWindowBtn"> ${that.lang == 6 ? '到这里去' : 'Directions'}</div>
				</div>`
				var opts = {
					width: 300, // 信息窗口宽度
					height: 150, // 信息窗口高度
					title: info.subtitle
				}
				var url =
					`http://api.map.baidu.com/direction?origin=latlng:${this.curLocation}|name:我的位置&destination=${info.address}&mode=driving&origin_region=${this.curCity}&destination_region=${info.city.name}&output=html&src=webapp.baidu.openAPIdemo`

				var infoWindow = new BMapGL.InfoWindow(content, opts);
				that.markerList[i].openInfoWindow(infoWindow)
				if (!infoWindow.isOpen()) {
					infoWindow.addEventListener('open', () => {
						document.getElementById("infoWindowBtn").onclick = function(e) {
							window.open(url)
						}
					})
				} else {
					document.getElementById("infoWindowBtn").onclick = function(e) {
						window.open(url)
					}
				}


			},
			setZoom(bPoints) {
				var view = this.map.getViewport(eval(bPoints));
				var mapZoom = view.zoom;
				var centerPoint = view.center;
				this.map.centerAndZoom(centerPoint, mapZoom / 1.1);
			},
			swiperPrev(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slidePrev()
			},
			swiperNext(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slideNext()
			},
			changeDealer(e, type) {
				console.log('?? ~ file: brand.vue:327 ~ changeDealer ~ e:', e)

				type == 'after' ? this.dealer = '' : this.after = ''
				console.log(this.Bomlist)
				this.Bomlist = type == 'after' ? this.afterList : this.dealerList
				this.agency = this.Bomlist[e]
				this.getSiteDetail(e)
				// this.changeMap(type)
			},
			changeMap() {
				var that = this
				if (!that.agency.longitude) return
				that.map.clearOverlays();
				var point = new BMapGL.Point(that.agency.longitude, that.agency.latitude);
				this.map.centerAndZoom(point, 17);
				// 创建点标记
				var marker = new BMapGL.Marker(point);
				this.map.addOverlay(marker);
				// 创建信息窗口
				var opts = {
					width: 200,
					height: 100,
				};
				var infoWindow = new BMapGL.InfoWindow(`<div id="infoWindow">${that.agency.address}</div>`, opts);
				this.url =
					`http://api.map.baidu.com/direction?origin=latlng:${this.curLocation}|name:我的位置&destination=${that.agency.address}&mode=driving&origin_region=${this.curCity}&destination_region=${that.agency.city.name}&output=html&src=webapp.baidu.openAPIdemo`
				this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
				if (!infoWindow.isOpen()) {
					infoWindow.addEventListener("open", function() {
						document.getElementById("infoWindow").onclick = function(e) {
							window.open(that.url)
						}
					})
				} else {
					document.getElementById("infoWindow").onclick = function(e) {
						window.open(that.url)
					}
				}
				// 点标记添加点击事件
				marker.addEventListener('click', function() {
					that.map.openInfoWindow(infoWindow, point); // 开启信息窗口
				});
			},
			// 获取经销商&售后列表
			async getDealerList() {
				var that = this
				this.afterList = []
				this.dealerList = []
				this.Bomlist = []
				var res = await this.$api.getDealerList({
					cate_no: '3rv54b65n',
					brand_id: that.id,
					city_id: that.city,
					province_id: that.city ? '' : that.province,
				})
				// 售后
				var after_res = await this.$api.getDealerList({
					cate_no: 'dr67n6673',
					brand_id: that.id,
					city_id: that.city,
					province_id: that.city ? '' : that.province,
				})
				this.afterList = after_res.list
				this.dealerList = res.list
				this.Bomlist = [...this.afterList, ...this.dealerList]
				that.createPoint()
			},
			// 获取省份列表
			getProvinceList() {
				this.$api.getProvinceList({
					brand_id: this.id,
					search_type: 'dealer'
				}).then(res => {
					this.provinceList = res.list
				})
			},
			// 获取城市
			getCityList() {
				this.getDealer();
				this.$api.getCityList({
					pid: this.province,
					brand_id: this.id,
					search_type: 'dealer'
				}).then(res => {
					this.cityList = res.list
				})

			},
			// 改变分页
			changePage(page) {
				this.page = page
				this.getActivityList()
			},
			// 品牌活动筛选改变
			changeSelectBox(e) {
				this.getActivityList(e)
			},
			// 获取信息
			getInfo() {
				this.banner = []
				this.id = this.$route.params.id
				this.$api.getBrandDetails({
					id: this.id
				}).then(res => {
					this.info = res.details
				})
				this.$api.getAtlasList({
					cate_no: 'd2x54552',
					brand_id: this.id
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.banner = res.list
					console.log('?? ~ file: brand.vue:455 ~ getInfo ~ this.banner:', this.banner)

					this.bannerFilterList = res.list.map(item => item.image)
					// this.slideTo(1)
				})
				this.getActivityList()
				this.getProvinceList()
				// this.getDealer()
				// this.init()
			},
			// 获取品牌活动列表
			getActivityList(e = {}) {
				var that = this
				that.activityList = []
				that.$api.getActivityList({
					cate_no: 'fdswset81',
					brand_id: this.id,
					form: 'all',
					city_id: e.c ? e.c : '',
					sort: e.s ? e.s : ''
				}, {
					page: that.page,
					limit: that.limit
				}).then(res => {
					that.$nextTick(() => {
						var list = res.list
						list.forEach(item => {
							item.atlas = item.atlas.split('|')
							item.swiperCur = 0
						})
						that.activityList = list
						that.total = Number(res.paginated.count)
					})
				})
			},
			// 陈列滑动
			slideChange() {
				this.bannerIndex = this.$refs.mySwiper1.swiper.realIndex
			}
		}
	}
</script>

<style lang="scss" scoped>
	// .page {
	// 	position: relative;
	// 	padding-top: 0;
	// 	background-color: #FFF;

	// 	// padding: 111px 0;
	// 	>img {
	// 		width: 100%;
	// 	}
	// }
	.barnd {
		position: relative;
		padding-top: 0;
		background-color: #FFF;

		// padding: 111px 0;
		>img {
			width: 100%;
		}

		.el-breadcrumb {
			height: 60px;
			line-height: 60px;
			border-bottom: #E1E1E1 1px solid;
			top: 0;
			width: 100%;
			padding: 0 111px !important;
			color: #333333 !important;
			font-size: 26px;
			font-family: SourceHanSansCN-Normal, sans-serif;
			text-transform: uppercase;
		}

		/deep/.el-breadcrumb__inner {
			color: #333333 !important;
		}

		/deep/.is-link {
			font-weight: normal;
			color: #97233F;
		}

		/deep/ .el-breadcrumb__separator {
			color: #333333 !important;
		}

		.textBox {
			padding: 0 192px;
			margin-bottom: 40px;
			font-family: SourceHanSansCN-Normal, sans-serif;
			font-size: 24px;
			font-weight: 350;
			color: #333333;

			.text {
				margin: 54px 0;

				/deep/ p,
				/deep/ span {
					line-height: 54px !important;
					font-size: 24px !important;
				}
			}
		}

		.title {
			font-family: Montserrat-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
			margin: 100px 0 30px;
			text-transform: uppercase;

			&.findUs {
				font-size: 32px;
				line-height: 34px;
			}
		}

		.mode2 {
			position: relative;
			height: 609px;
			background-size: 100% 100%;
			background-repeat: no-repeat;

			.arrows {
				width: 118px;
				height: 87px;
				position: absolute;
				left: 39px;
				z-index: 2;
				top: calc(50% - 100px);

				&:nth-child(2) {
					transform: rotate(180deg);
					left: auto;
					right: 39px;
				}

			}

			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				filter: blur(5px);
				z-index: 1;
			}

			&::after {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.17);
				z-index: 2;
				left: 0;
				top: 0;
			}

			&::before {
				content: '';
				width: 100%;
				height: 2px;
				background-color: #FFF;
				position: absolute;
				bottom: 110px;
				z-index: 2;

			}

			.banner {
				width: 659px;
				height: 374px;
				border-radius: 14px;
			}

			/deep/ .swiper-container {
				position: relative;
				width: 85%;
				margin: 0 auto;
				height: 609px;
				z-index: 3;
			}

			/deep/.swiper-wrapper {
				height: 450px;
				position: relative;
			}

			/deep/ .swiper-slide {
				text-align: center;
				font-size: 18px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				-webkit-justify-content: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				align-items: center;
				transition: 300ms;
				transform: scale(0.8);
				position: relative;

				p {
					width: 100%;
					font-family: SourceHanSansCN-Medium, sans-serif;
					color: #FFF;
					font-size: 22px;
					position: absolute;
					font-weight: 500;
					bottom: -180px;
					text-align: center;
					line-height: normal;
					cursor: pointer;

					&::after {
						content: '';
						width: 30px;
						height: 30px;
						background-color: #FFF;
						border-radius: 50px;
						display: block;
						position: absolute;
						left: calc(50% - 10px);
						top: -47px;
					}
				}
			}

			/deep/ .swiper-slide-active,
			/deep/ .swiper-slide-duplicate-active {
				transform: scale(1);

				p {
					font-size: 32px;
					bottom: -110px;
					transform: scale(1);

					&::after {
						width: 20px;
						height: 20px;
						top: -35px;
						transform: scale(1);
						padding: 5px;
						border: 1px solid #FFF;
						background-clip: content-box;
					}
				}
			}

		}

		.mode3 {
			padding-top: 192px;

			.title-flexBox {
				padding: 0 192px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 50px;
			}

			.title {
				margin: 0;
				margin-bottom: 10px;
			}

			.mode3-list {
				.mode3-list-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 41px 192px;
					box-sizing: content-box;
					height: 370px;

					&:nth-child(odd) {
						background-color: #F2F2F2;

						/deep/.swiper-container {
							background-color: #F2F2F2;
						}
					}

					&:nth-child(even) {
						background-color: #FFF;

						/deep/ .swiper-container {
							background-color: #FFF;
						}
					}

					.mode3-list-item-text {
						width: 882px;
						font-family: SourceHanSansCN-Regular, sans-serif;
						font-size: 24px;
						font-weight: normal;
						line-height: 40px;
						letter-spacing: 0em;
						color: #787878;
						position: relative;
						height: 100%;

						.title {
							text-transform: none;
						}

						>div:nth-child(1) {
							width: 100%;
							overflow: hidden;
							height: calc(100% - 54px);
							padding-right: 20px;

							// overflow: hidden;
							// text-overflow: ellipsis;
							.mode3-list-item-text-describe {
								overflow: hidden;
								height: 230px;
								overflow-y: scroll;
							}
						}

						>div:last-child {
							width: 100%;
							display: flex;
							align-items: bottom;
							position: absolute;
							right: 0;
							bottom: 0px;
							justify-content: flex-end;
							height: 64px;
							line-height: 80px;
							padding-right: 20px;

							img {
								width: 64px;
								height: 64px;

								&:first-child {
									margin-right: 20px;
									transform: rotate(180deg);
								}
							}

							i {
								margin-right: 20px;
							}

							>div:last-child {
								line-height: 84px;
							}
						}
					}

					.title {
						font-family: SourceHanSansCN-Bold, sans-serif;
						font-size: 26px;
						font-weight: bold;
						color: #97233F;
					}
				}

				.mode3-right {
					position: relative;
					height: 280px;

					&::after {
						width: 200px;
						height: 260px;
						background: #97233F;
						top: -15px;
						left: -15px;
						position: absolute;
						content: '';
					}

					/deep/.swiper-container {
						width: 520px;
					}

					/deep/ .swiper-slide {
						width: 520px;
					}

					.previewImage {
						width: 520px;
						height: 280px;
					}

					.arrows1 {
						width: 56px;
						height: 56px;
						position: absolute;
						left: -70px;
						z-index: 9;
						top: calc(50% - (56px/2));

						&:nth-child(2) {
							left: auto;
							right: -70px;
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.bottom {
			background: #F3F3F3;
			// padding: 82px 360px;
			font-family: SourceHanSansCN-Normal, sans-serif;
			font-size: 18px;
			font-weight: 350;
			line-height: 36px;
			color: #333333;
			display: flex;
			justify-content: space-between;
			font-family: SourceHanSansCN-Normal, sans-serif;
			font-size: 24px;
			font-weight: 350;
			align-items: center;

			.map {
				flex: 1;
				// width: 50%;
				height: 800px;
				background-color: antiquewhite;
			}

			.right {
				flex: 1;
				// width: 50%;
				padding: 60px 75px;
				height: 800px;
				box-sizing: border-box;

				/deep/ p,
				/deep/ span {
					line-height: 60px;
				}

				.rightList {
					height: calc(100% - 152px);
					overflow-y: scroll;

					>div {
						cursor: pointer;
					}

					.rightList-item {
						position: relative;
						padding-left: 40px;
					}

					.num {
						width: 50px;
						margin-right: 0px;
						position: absolute;
						left: 0;
						top: 3px;
						display: inline-block;
						line-height: normal;
						font-size: 24px;
					}

					.rightListTit {
						color: #97233F;
						font-weight: 700;
						font-size: 24px;
					}

					// .describe {
					//   margin-bottom: 10px;
					// }
				}

				// overflow: hidden;
			}

			.inputBox {
				margin-bottom: 20px;

				>div {
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;

					.el-select {
						width: calc(50% - 10px);
					}
				}

				/deep/.el-select__caret {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}

				.el-select /deep/ input {
					height: 56px;
				}

				/deep/ .el-input__icon {
					height: 56px;
				}
			}

			p {
				line-height: 50px;
			}

			.bottom-title {
				margin-bottom: 30px;
				color: #97233F;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 32px;
				font-weight: bold;
			}
		}

		.lengthways {
			display: block;
			padding-bottom: 50px;

			P {
				line-height: 40px;
			}

			.map {
				width: 100%;
				height: 600px;
			}

			.right {
				width: calc(100% - 178px * 2);
				margin: 50px auto;
				display: flex;
				justify-content: space-between;
				font-size: 22px;

				.bottom-title {
					font-size: 28px;
				}

				.tit {
					font-size: 32px;
					color: #97233F;
					font-weight: bold;
					margin-bottom: 68px;
					position: relative;

					&::after {
						content: '';
						width: 95px;
						height: 4px;
						background-color: #97233F;
						position: absolute;
						bottom: -20px;
						left: 0;
					}
				}
			}
		}

		.el-pagination {
			text-align: center;
			margin: 100px 0;

			/deep/ .active {
				background-color: #97233F !important;
			}
		}

		.mode4 {
			height: 540px;
			background: url(../assets/index/mode7-bg.png) no-repeat top/100% 100%;
			color: #FFF;
			padding: 0 108px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.mode4-text {
				width: 750px;
			}

			.mode4-textCn {
				width: 800px;
			}

			.titBottom {
				color: #FFF;
			}

			.titTop {
				font-size: 32px;
			}

			.titText {
				font-size: 35px;
				font-weight: bold;
				line-height: 60px !important;
				margin-bottom: 109px;
			}

			.titbtn {
				padding: 0 20px;
				display: inline-block;
				width: 200px;
				height: 56px;
				border-radius: 6px;
				background: #97233F;
				line-height: 56px;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 22px;
				color: #FFFFFF;
				text-align: center;
				margin-top: 50px;
				cursor: pointer;
				text-transform: uppercase;
			}
		}

		/deep/ .swiper-slide-active {
			z-index: 3;
		}
	}

	/deep/ .BMapLabel {
		background-color: transparent !important;
		border: none !important;
		width: 40px;
		height: 64px;
		line-height: 44px;
		text-align: center;
		color: #FFF;
		font-size: 10px !important;
		background: url(../assets/point.png) no-repeat top/100% 100% !important;
	}

	/deep/ .BMap_bubble_pop {
		width: 550px !important;
		min-height: 308px !important;
		height: auto !important;
		padding: 28px 35px !important;
		top: 80px !important;
		left: 238px !important;

		.BMap_bubble_top {
			height: auto !important;
		}

		img {
			top: auto !important;
			left: 50% !important;
			transform: translateX(-55%) !important;
			bottom: -30px !important;
			z-index: -1 !important;
		}
	}

	/deep/ .BMap_bubble_title {
		margin-top: 20px;
		color: #97233F !important;
		font-weight: bold;
		font-size: 26px;
		font-family: SourceHanSansCN-Bold, sans-serif;
		word-wrap: break-word;
		white-space: pre-wrap !important;
		height: auto !important;
		width: 100% !important;
	}

	/deep/ .BMap_bubble_center {
		min-height: 160px;
		height: auto !important;
		width: 100% !important;

		.BMap_bubble_content {
			min-height: 180px;
			height: auto !important;
			width: 100% !important;
			position: relative !important;
		}
	}

	/deep/ #infoWindow {
		width: 100%;
		height: calc(100% - 112px);
		font-size: 20px !important;
		position: relative;

		.infoWindowText {
			margin-top: 10px;
			line-height: 36px;
		}

		.btn {
			width: 100%;
			height: 56px;
			background: #97233F;
			color: #FFF;
			text-align: center;
			line-height: 50px;
			margin: 20px auto;
			margin-bottom: 0;
			// position: absolute;
			// bottom: 0;
		}
	}

	/deep/.el-select-dropdown__item {
		span {
			line-height: 34px !important;
		}
	}
</style>